<template>
  <div style="padding: 0px;">
    <van-nav-bar title="产品列表" :border="false" :fixed="true"/>
    <van-divider></van-divider>
    <div style="position: fixed;height: 100%; width: 100%;padding: 10px 0 80px; box-sizing: border-box">
<!--      <van-pull-refresh style="height: 100%; width: 100% " v-model="refreshing" @refresh="onRefresh">-->
<!--       -->
<!--      </van-pull-refresh>-->
      <van-list
          style="height:100%;width:100%;overflow-y:auto;padding-bottom: 20px; box-sizing: border-box;overflow-x: hidden"
          v-model="loading"
          :finished="finished"
          :offset="150"
          error-text="请求失败，点击重新加载"
          finished-text="没有更多了"
          @load="onLoad"
      >
        <div v-for="item in list" :key="item.userId" @click="showItem(item)">
          <div v-if="item.is_sale" style="z-index: 200;position: relative;top: 10px;left: 15px;padding: 10px 0; box-sizing: border-box">
            <van-tag type="success" color="#FFA500">在售</van-tag>
          </div>
          <div style="z-index: 100;position: relative;">
            <van-cell-group inset :border="false">
              <van-cell :border="false" title="名称" :value="(item.name === undefined ? 'null' : item.name)"/>
              <!--                  <van-cell title="类型" :value="(item.type === undefined ? '' : item.type)"/>-->
              <van-cell :border="false" title="起售金额"
                        :value="(item.start_amount === undefined ? '0' : item.start_amount )+' 万'"/>
              <van-cell :border="false" title="截止日期" :value="(item.end_date === undefined) ?'':item.end_date"/>
            </van-cell-group>
            <van-divider></van-divider>
        </div>
      </van-list>
    </div>
  </div>
</template>

<script>
import {Toast} from 'vant'
import store from "@/store";
import qs from "qs";
import {Base64} from "js-base64";

export default {
  name: 'Home',
  data() {
    return {
      list: [],
      refreshing: false,
      loading: false,
      finished: false,
      isLoading: false,
      error: false,
      page: 1,
      page_size: 10
    }
  },
  mounted() {
    store.commit('toggleTabbarShow', true);
  },
  methods: {
    showItem (record) {
      this.$router.push({ path: '/product/'+ record.id, query: { data: Base64.encode(qs.stringify(record))} })
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = false;
      this.onLoad();
    },
    onLoad() {
      this.$http.get("/product/customer_product/",{page: this.page,page_size: this.page_size})
          .then(res => {
            if (this.page === 1) {
              this.list = [];
              this.refreshing = false;
            }
            console.log(res);
            // 加载状态结束
            this.loading = false;
            if (res && res.code === 1000) {
              if (res.data.page_count > this.page) {
                // Toast("刷新成功");
                this.page++;
              }else {
                // 数据全部加载完成
                this.finished = true;
              }

              this.list = res.data.entries;
            }
          }).catch(err => {
            this.error = true
          })
    },
    // // 滚动到底部
    // scrollToBottom: function () {
    //   this.$nextTick(() => {
    //     let container = this.$el.querySelector(".content");
    //     container.scrollTop = container.scrollHeight;
    //   })
    // },
  },
  // updated(){
  //   this.scrollToBottom()
  // }
}
</script>

<style>
.title {
  font-weight: 600;
  font-size: 18px;
}

.van-pull-refresh {
  height: 90%;
}

/*.van-pull-refresh /deep/ .van-pull-refresh__track {*/
/*  height: inherit;*/
/*}*/
</style>
